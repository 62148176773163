import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'

const language = () => {

    const { i18n } = useTranslation()

    const metaData = useStaticQuery(
      graphql`
        query {
          site {
            siteMetadata {
              languages
            }
          }
        }
      `
    )
    const langs = metaData.site.siteMetadata.languages
    let i18nLang
    
    if (typeof i18n.language !== 'undefined') {
      i18nLang = i18n.language.includes('-')
        ? i18n.language.split('-')[0]
        : i18n.language
    }
    
    return  typeof langs.find(lang => lang === i18nLang) !== 'undefined'
        ? langs.find(lang => lang === i18nLang)
        : 'en'

}

export default language;
