import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import { navigate } from '../Link'

const HeaderConfig = () => {
  const { i18n } = useTranslation()

  const EN = 'en'
  const USD = 'usd'
  const UNDEFINED = 'undefined'

  return {
    metaData: useStaticQuery(
      graphql`
        query {
          site {
            siteMetadata {
              languages
              title
            }
          }
        }
      `
    ),

    newToken:
      /coinigy\_csrf\_token\=([^$;]*)/gi.exec(document.cookie) !== null
        ? /coinigy\_csrf\_token\=([^$;]*)/gi.exec(document.cookie)[1]
        : '',

    userSessionExpired: useSelector(state => state.user.session_expired),

    csrfCoinigy: useSelector(state => state.user.csrf_token),

    userSettings: useSelector(state => state.user.user_settings),

    userDarkModeSelector: useSelector(state => state.user.darkMode),

    headerDark: useSelector(state => state.user.headerDark),

    userDarkMode: (userSessionExpired, userDarkModeSelector, userSettings) =>
      userSessionExpired
        ? userDarkModeSelector
        : typeof userSettings !== UNDEFINED
        ? userSettings.dark_mode
        : false,

    userDarkModeSettings: userSettings =>
      typeof userSettings !== UNDEFINED ? userSettings.dark_mode : false,

    settingsCurr: userSettings =>
      typeof userSettings !== UNDEFINED
        ? userSettings.pref_native_currency
        : USD,

    i18nLang: () => {
      if (typeof i18n.language !== UNDEFINED) {
        return i18n.language.includes('-')
          ? i18n.language.split('-')[0]
          : i18n.language
      }
    },

    setLang: (langs, i18nLang) => {
      return typeof langs.find(lang => lang === i18nLang) !== UNDEFINED
        ? langs.find(lang => lang === i18nLang)
        : EN
    },

    getCookie: setAcceptedCookiesValue => {
      const isCookiesAccepted =
        typeof document !== UNDEFINED &&
        document.cookie.indexOf('accepted_cookies') > -1

      if (isCookiesAccepted) {
        setAcceptedCookiesValue(
          document.cookie
            .split('; ')
            .find(row => row.startsWith('accepted_cookies='))
            .split('=')[1]
        )
      }
    },

    defaultConfig: (location, lang) => {

        if (
          typeof document !== 'undefined' &&
          document.cookie.includes('coinigy_csrf_token=<!DOCTYPE html>')
        ) {
          document.cookie =
            'coinigy_csrf_token=<!DOCTYPE html>; domain=.coinigy.com;path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
        }

        if (
          typeof location.href !== 'undefined' &&
          location.href.split('/')[3].length !== 2
        ) {
          let newLocation = location.href.replace('.com/', `.com/${lang}/`)

          let path = newLocation.split(`/${lang}/`)[1]
          navigate(`/${lang}/${path}`)
        }

        useEffect(() => {
          if (
            typeof location.href !== 'undefined' &&
            location.href.split('/')[3].length === 2 &&
            location.href.split('/')[3] !== lang
          ) {
            i18n.changeLanguage(location.href.split('/')[3])
          }
        }, [location.href])
        
    },
  }
}

export default HeaderConfig
