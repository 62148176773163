import * as styles from './Home.module.css'
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Title, Section, Box, Text, Button } from '../../components/Core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Link from '../../components/Link'
import setLanguage from '../../helpers/languageConfig'


const GetStarted = props => {
  const { t } = useTranslation('landing', { useSuspense: false });
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  let lang = setLanguage()

  return (
    <>
      {/* <!-- GetStarted section --> */}
      <Section bg={userDarkMode ? 'black' : 'bg'}>
        <Container>
          <Box pb={[4, null, null, 4]}>
            <Row className="justify-content-center">
              <Col lg="7" xl="6">
                <div className="section-title text-center">
                  <Title
                    id="SignupTitle"
                    color={userDarkMode ? 'light' : 'dark'}
                    mb={[0, null, null, 2]}
                  >
                    {t('ready')}
                  </Title>
                </div>
              </Col>
            </Row>
          </Box>
          <Container className={styles.mailInputContainer}>
            <Row className="justify-content-center">
              <a
                aria-label={t('signup')}
                href={process.env.LS_SIGNUP}
                rel="noopener noreferrer"
              >
                <Button
                  type="submit"
                  style={{ fontSize: '16px' }}
                  background={'#124D95'}
                >
                  {t('openAccount')}
                </Button>
              </a>
            </Row>
          </Container>
          <Row className="mt-5" style={{ justifyContent: 'center' }}>
            <Text color={userDarkMode ? 'white' : 'black'}>
              {t('haveQuestions')}
            </Text>
            <Link to={`/${lang}/learning/faqs`}>
              <Text
                style={{ textDecoration: 'underline' }}
                className="ml-2"
                color={userDarkMode ? '#3B96FF' : '#3377C6'}
              >
                {t('viewFAQS')}
              </Text>
            </Link>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default GetStarted
